import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';

import { useSearchParams } from 'next/navigation';
import type { Slot } from '../../../amplienceTypes/content';

import { listAllChildren } from '../../dom';

type LayoutDesignContextType = {
  hasStickyProductImageGallery?: boolean;
  hasProductAccordions?: boolean;
  viaProductLabel?: boolean;
};

const LayoutDesignContext = createContext<LayoutDesignContextType>({
  hasStickyProductImageGallery: undefined,
  hasProductAccordions: undefined,
  viaProductLabel: false,
});

const PRODUCT_LABEL = 'productLabel';

export const useLayoutDesignContext = () => useContext(LayoutDesignContext);

export const LayoutDesignContextProvider = ({
  children,
  layoutComponents = [],
  ...props
}: {
  children: ReactNode;
  layoutComponents?: Slot[];
} & LayoutDesignContextType) => {
  const searchParams = useSearchParams();

  const value = useMemo(() => {
    const layoutComponentsChildren = layoutComponents.reduce<Slot[]>(listAllChildren, []);

    return {
      hasStickyProductImageGallery: Boolean(
        layoutComponentsChildren.find(
          ({ name, properties }) => name === 'productImageGallery' && properties?.content?.sticky
        )
      ),
      hasProductAccordions: Boolean(
        layoutComponentsChildren.find(({ name }) => name === 'productAccordions')
      ),
      viaProductLabel: Boolean(searchParams.get('utm_campaign') === PRODUCT_LABEL),
      ...props,
    };
  }, [layoutComponents, props, searchParams]);

  return <LayoutDesignContext.Provider value={value}>{children}</LayoutDesignContext.Provider>;
};
